import { createContext, Dispatch, SetStateAction } from 'react'

interface ContextState {
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  customerReportOpen: boolean
  setCustomerReportOpen: Dispatch<SetStateAction<boolean>>
  invoiceEmailOpen: boolean
  setInvoiceEmailOpen: Dispatch<SetStateAction<boolean>>
  loadingState: boolean
  setLoadingState: Dispatch<SetStateAction<boolean>>
}
const NavContext = createContext({} as ContextState)

export { NavContext }
