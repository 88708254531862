import React, { useContext, useEffect, useState } from 'react'
import { attributes } from '../content/global-header-nav.md'
import Link from 'next/link'
import Image from 'next/image'
import { signOut, useSession } from 'next-auth/react'
import Logo from './shared/Logo'
import TruckIcon from './icons/TruckIcon'
import FormIcon from './icons/FormIcon'
import { NavDropdown } from './NavDropdown'
import { timeOfDay } from '@/utils/ui-helper'
import AccountNavItem from './shared/AccountNavItem'
import { UserRoleEnum } from '@/types/cognito'
import { useRouter } from 'next/router'
import BashIcon from './icons/BashIcon'
import DocumentWithCheckIcon from './icons/DocumentWithCheckIcon'
import ContactModal from './ContactModal'
import { AccountSearch } from './portal/ui/search/AccountSearch'
import { AccountSearchResultProps } from '@/libs/types'
import { accountsSearch } from '@/services/searchAccounts'
import { Session } from 'next-auth'
import { useBreakpoint } from 'use-breakpoint'
import { BREAKPOINTS } from '@/libs/constants'
import { NavContext } from './context/NavContext'

const DesktopLinks = [
  {
    href: '/service-area',
    title: 'Service Area',
    target: '_self',
  },
  {
    href: '/join-the-team',
    title: 'Join The Team',
    target: '_self',
  },
]
const links = [
  {
    href: '/service-area',
    title: 'Service Area',
    target: '_self',
  },
  {
    href: '/join-the-team',
    title: 'Join The Team',
    target: '_self',
  },
  {
    href: '/schedule-pickup',
    title: 'Schedule Pickup',
    target: '_self',
  },
  {
    href: '/get-forms',
    title: 'Get Forms',
    target: '_self',
  },
]

interface Props {
  session: Session | null
  showApplyLink?: boolean
  status: string
}

export const navButtonStyles =
  'duration-300 whitespace-nowrap font-ttHovesMedium text-nptAGreen focus:text-nptDGreen text-base hover:text-nptDGreen'

export const navButtonStylesMobile =
  'w-full h-[64px] p-5 text-center text-white duration-300 border-b border-gray-400 font-ttHovesMedium hover:bg-nptDGreen focus:bg-nptDGreen'

const Header: React.FunctionComponent<Props> = ({
  session,
  showApplyLink,
  status,
}) => {
  const { navOpen, setNavOpen } = useContext(NavContext)
  const [showMobileMenu, mobileMenuIsOpen] = useState<boolean>(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchResults, setSearchResults] = useState<
    AccountSearchResultProps[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)
  const router = useRouter()
  const path = router.pathname
  const isAdmin = session?.user.role === 'nopk-admin'

  const isPortal = router.pathname.includes('/account')
  const isAdminPortal = router.pathname.includes('/admin')
  const isAdminDetailsPage =
    router.pathname.includes('portal/account/shipments') && isAdmin
  const isAdminAccountView = router.pathname.includes('admin/account')
  const loggedIn =
    (status === 'authenticated' && isPortal) ||
    (status === 'authenticated' && isAdminPortal)
  const { applyLinkText, applyLinkUrl } = attributes
  const displayAdminSearch =
    isAdmin && path !== '/portal/admin' && (isAdminPortal || isAdminDetailsPage)
  const { setCustomerReportOpen } = React.useContext(NavContext)
  const { setInvoiceEmailOpen } = React.useContext(NavContext)

  useEffect(() => {
    const searchAccounts = async () => {
      setLoading(true)
      const accountResults: AccountSearchResultProps[] = await accountsSearch(
        searchQuery
      )
      setSearchResults(accountResults)
      setLoading(false)
    }

    if (searchQuery.length > 2) {
      searchAccounts()
    } else {
      setSearchResults([])
    }
  }, [searchQuery])

  const clearSearch = () => setSearchQuery('')

  const { breakpoint } = useBreakpoint(BREAKPOINTS)

  useEffect(() => {
    if (breakpoint === 'L' || breakpoint === 'XL') {
      setNavOpen(false)
    }
  }, [breakpoint, setNavOpen])

  useEffect(() => {
    const handleRouteChange = () => {
      setNavOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, setNavOpen])

  const portalLinks = [
    {
      href:
        status === 'authenticated'
          ? `${
              session?.user.role === 'nopk-admin'
                ? '/portal/admin'
                : '/portal/account'
            }`
          : '/login',
      title: !loggedIn ? `Customer Portal` : 'My Account',
      target: '_self',
    },
    loggedIn
      ? {
          href: '/portal/account/invite-users',
          title: 'Invite Users',
          target: '_self',
        }
      : undefined,
  ].filter(Boolean)

  const ResourcesLinks = [
    {
      links: [
        {
          linkLabel: 'Connect Your TMS',
          description:
            'Connect your TMS, to our API for custom quotes, to schedule pickups and track and trace your shipments',
          linkUrl: 'https://api.nopk.com/v1/swagger',
          icon: <BashIcon color="#56A97F" />,
        },
        {
          linkLabel: 'Schedule A Pickup',
          description:
            'Schedule a pickup for next day delivery across most of our footprint',
          linkUrl: '/schedule-pickup',
          icon: <TruckIcon color="#56A97F" />,
        },
        {
          linkLabel: 'View Images',
          description:
            'Sign in to your Customer Portal in order to track shipments and download images',
          linkUrl: '/login',
          icon: <DocumentWithCheckIcon color="#56A97F" />,
        },
        {
          linkLabel: 'Get Forms',
          description: 'Download Freight, Claims, Employment, and Misc. Forms ',
          linkUrl: '/get-forms',
          icon: <FormIcon color="#56A97F" />,
        },
      ],
    },
  ]

  return (
    <>
      <header
        className={`  ${
          navOpen ? 'z-50 bottom-0 overflow-y-scroll absolute top-0' : 'z-10'
        }`}
      >
        <div className="flex items-center pb-4 m-auto max-w-screen-2xl p-11 pt-14 lg:pt-6">
          <div className="absolute mt-1 top-16 left-3 lg:hidden">
            <button
              type="button"
              className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset"
              aria-expanded="false"
              onClick={() => {
                setNavOpen(!navOpen)
              }}
            >
              <span className="sr-only">Open menu</span>
              <Image
                width="25"
                height="25"
                src="/images/mobile-menu-icon.svg"
                alt="Toggle mobile menu"
                className="w-10 h-10"
              />
            </button>
          </div>
          <div className="flex justify-center w-full lg:hidden">
            <Logo />
          </div>

          <nav className="items-center justify-between flex-1 hidden w-full pt-2 text-sm lg:flex xl:text-base">
            <Logo />
            {!isAdminPortal && !isAdminDetailsPage ? (
              <div className="absolute right-1/2 translate-x-1/2 flex items-center justify-between flex-1 text-center md:gap-x-[35px] lg:gap-x-[65px] h-[75px]">
                {DesktopLinks.map((link, i) => (
                  <Link
                    key={`navLink_${i}`}
                    href={link.href}
                    className={navButtonStyles}
                    target={link.target}
                  >
                    {link.title}
                  </Link>
                ))}
                <NavDropdown
                  header="Resources"
                  buttonSections={ResourcesLinks}
                />
                <button
                  tabIndex={0}
                  id="contact-modal"
                  className={navButtonStyles}
                  onClick={() => {
                    setIsContactModalOpen(!isContactModalOpen)
                  }}
                >
                  Contact Us
                </button>
              </div>
            ) : (
              displayAdminSearch && (
                <AccountSearch
                  inputPlaceholder="Search using account name"
                  searchQuery={searchQuery}
                  onChangeCallback={setSearchQuery}
                  searchResults={searchResults}
                  clearSearch={clearSearch}
                  loading={loading}
                  isNavSearch={true}
                />
              )
            )}
            <AccountNavItem timeOfDay={timeOfDay()} />
          </nav>
        </div>

        <div className="w-full h-1 bg-gradient-to-r from-green-classic to-nptBGreen" />

        {navOpen && (
          <div className="fixed top-0 z-40 w-full h-full pb-4 overflow-y-scroll transition origin-top-right transform bg-white pt-14 lg:hidden">
            <div className="absolute mt-1 top-16 left-3">
              <button
                type="button"
                className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset"
                aria-expanded="false"
                onClick={() => {
                  setNavOpen(!navOpen)
                }}
              >
                <span className="sr-only">Close menu</span>
                <Image
                  width="25"
                  height="25"
                  src="/images/mobile-close-icon.svg"
                  alt="Toggle mobile menu"
                  className="w-10 h-10"
                />
              </button>
            </div>

            <Logo />

            <nav className="grid mt-4 bg-green-classic">
              <div className="h-1 bg-gradient-to-r from-green-classic to-nptBGreen" />
              {links.map((link, i) => (
                <Link
                  key={`mobileNavLink_${i}`}
                  href={link.href}
                  target={link.target}
                >
                  <button
                    className={navButtonStylesMobile}
                    type="button"
                    onClick={() => setNavOpen(!navOpen)}
                  >
                    {link.title}
                  </button>
                </Link>
              ))}
              <button
                tabIndex={0}
                className="p-5 text-center text-white duration-300 border-b border-gray-400 font-ttHovesMedium hover:bg-nptDGreen focus:bg-nptDGreen"
                onClick={() => {
                  setIsContactModalOpen(!isContactModalOpen)
                }}
              >
                Contact Us
              </button>
              {showApplyLink && (
                <a
                  href={applyLinkUrl}
                  className="p-5 text-center text-white duration-300 border-b border-gray-400 font-ttHovesMedium hover:bg-nptDGreen focus:bg-nptDGreen"
                  target="_blank"
                  rel="noreferrer"
                >
                  {applyLinkText}
                </a>
              )}
              {portalLinks.map((link, i) => {
                if (!link) return
                return (
                  <Link
                    key={`mobileNavLink_${i}`}
                    href={link.href}
                    target={link.target}
                  >
                    <button
                      className={navButtonStylesMobile}
                      type="button"
                      onClick={() => mobileMenuIsOpen(!showMobileMenu)}
                    >
                      {link.title}
                    </button>
                  </Link>
                )
              })}
              {loggedIn ? (
                <button
                  className="w-full p-5 text-center text-white duration-300 border-b border-gray-400 font-ttHovesMedium hover:bg-nptDGreen focus:bg-nptDGreen"
                  onClick={() => {
                    signOut({ callbackUrl: '/login' })
                    mobileMenuIsOpen(!showMobileMenu)
                  }}
                  type="button"
                >
                  Logout
                </button>
              ) : null}
              {isAdminAccountView && (
                <>
                  <button
                    className={navButtonStylesMobile}
                    type="button"
                    onClick={(e) => {
                      // if (showMobileMenu)
                      e.preventDefault()
                      if (navOpen) setNavOpen(!navOpen)
                      setCustomerReportOpen(true)
                    }}
                  >
                    Create Customer Reports
                  </button>
                  <button
                    className={navButtonStylesMobile}
                    type="button"
                    onClick={(e) => {
                      // if (showMobileMenu)
                      e.preventDefault()
                      if (navOpen) setNavOpen(!navOpen)
                      setInvoiceEmailOpen(true)
                    }}
                  >
                    Create Invoice Emails
                  </button>
                </>
              )}
            </nav>
          </div>
        )}
      </header>
      {isContactModalOpen && (
        <ContactModal
          isContactModalOpen={isContactModalOpen}
          setIsContactModalOpen={setIsContactModalOpen}
        />
      )}
    </>
  )
}

export default Header
